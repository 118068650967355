import React from 'react';
import { getUser } from '../services/auth';

const Profile = () => (
  <>
    <h1>Your profile</h1>
    <ul>
      <li>
        Name:{' '}
        {getUser() &&
          getUser().user_metadata &&
          getUser().user_metadata.full_name}
      </li>
      <li>E-mail: {getUser().email}</li>
    </ul>
  </>
);

export default Profile;
