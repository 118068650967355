import React from 'react';
import { Router } from '@reach/router';
import { Link, StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import PrivateRoute from '../components/privateRoute';
import Profile from '../components/profile';
import Login from '../components/login';
import { BlogPost as Post } from '../templates/blog-post';
import { initAuth } from '../services/auth';

initAuth();

const QUERY_PRIVATE_MDX = graphql`
  query QUERY_PRIVATE_MDX {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { frontmatter: { private: { eq: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          code {
            body
          }
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

const App = props => (
  <Layout>
    {/* link and route to private posts  */}
    <StaticQuery query={QUERY_PRIVATE_MDX}>
      {data => {
        const posts = data.allMdx.edges;
        return posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key="node.fields.slug">
              <Link
                style={{ boxShadow: `none` }}
                to={`/app/${node.fields.slug}`}
                key={`link-${node.fields.slug}`}
              >
                {title}
              </Link>
              <Router>
                <PrivateRoute
                  path={`/app${node.fields.slug}`}
                  key={`/route-${node.fields.slug}`}
                  component={Post}
                  post={node}
                  pageContext={{}}
                />
              </Router>
            </div>
          );
        });
      }}
    </StaticQuery>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <Login path="/app/login" />
    </Router>
  </Layout>
);

export default App;
